import React, { useState } from 'react';
import { faArrowRight, faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PortBar = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleReport = () => {
  // Replace the Google link with your desired link
  const googleLink = 'https://docs.google.com/forms/d/e/1FAIpQLSddVx6NemtiZH7X-MCO3kjEKJYCNJzrMKqI8HpkLpMmaMJytg/viewform';
  
  // Open a new window or tab with the Google link
  window.open(googleLink, '_blank');

  // Log the report action (you can remove or modify this line)
  console.log("Reported!");
  };

  return (
    <div
      className={`bg-box rounded-md ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="p-2 rounded-[5px] relative">
        {/* Report flag */}
        {isHovered && (
          <div className="report-flag  absolute top-2 right-2" style={{ color: 'red' }} >
            <FontAwesomeIcon icon={faFlag} onClick={handleReport} />
          </div>
        )}

        <span className="font-roboto text-white text-lg">
          #{props.number} - {props.name}
        </span>
        <br />
        <a href={props.url} target="_blank" className="font-roboto leading-[16.00px] text-blue-500 text-sm w-[94%] sm:w-full">
          Link <FontAwesomeIcon icon={faArrowRight} />
        </a>
        <br />
        <span className="font-roboto text-orange-600 text-sm">Awards: {props.award} {props.place} </span>
        <br />
        <span className="font-roboto text-white text-sm mb-[13px]">Level: {props.level}</span>
        <br />
        <span className="font-roboto text-white text-sm">Season: {props.seasonDisplay}</span>
        <br />
        <span className="font-roboto text-white text-sm">Region: {props.region}</span>
        <br />
      </div>
    </div>
  );
};

export default PortBar;
